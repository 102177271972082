<template>
    <div class="archive">
        <div class="card">
            <div class="card__header">
                <h1 class="card__title">{{ $t('archive.title') }}</h1>

                <div class="card__subheader">
                    <ShipmentFilter @filter="filter = $event" :show-state="true" :show-transport-type="true" :initial-date="$route.query.initialDate"></ShipmentFilter>

                    <div class="data-table__header-button">
                        <Export :enabled="initialized" :sorting="sorting" :filter="filter" :number-of-results="total" :selected-shipments="exportShipments" url="/api/v2/shipments/archive/export"></Export>
                    </div>
                </div>
            </div>

            <div v-if="!initialized">{{ $t('global.loading') }}</div>

            <div v-if="initialized && shipments.length > 0" class="card__table">
                <table class="data-table" :class="{ 'data-table--updating': updating }">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{ $t('shipment.number') }}</th>
                            <th><TableSortingField field="reference" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.reference') }}</TableSortingField></th>
                            <th><TableSortingField field="sender" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.from') }}</TableSortingField></th>
                            <th><TableSortingField field="receiver" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.to') }}</TableSortingField></th>
                            <th>{{ $t('shipment.cargo') }}</th>
                            <th><TableSortingField field="empfangsdatum" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.deliveryDate') }}</TableSortingField></th>
                            <th>{{ $t('global.details') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="shipment in shipments">
                            <td>
                                <ul class="checkbox-group checkbox-group--inline">
                                    <li class="checkbox-group__item">
                                        <div class="checkbox-group__input">
                                            <input :id="`export[${shipment.key}]`" type="checkbox" :value="shipment.key" v-model="exportShipments">
                                            <label :for="`export[${shipment.key}]`"></label>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td class="data-table--bold data-table--no-break">
                                <div class="data-table__mobile-label">{{ $t('shipment.number') }}</div>
                                {{ shipment.externalNumber }}
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('shipment.reference') }}</div>
                                {{ shipment.reference }}
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('shipment.from') }}</div>
                                <div class="data-table__count-container">
                                    <div class="data-table__count" v-if="shipment.from.length > 1">+{{ shipment.from.length - 1 }}</div>

                                    <Label :text="`${shipment.from[0].name}, ${shipment.from[0].location}`" />

                                    <!-- States -->
                                    <small v-if="getStateName(shipment) === 'cancelled'" class="data-table__state text-red">{{ $t('shipment.cancelled') }}</small>

                                    <template v-if="getStateName(shipment) !== 'cancelled'">
                                        <template v-if="shipment.isTour && shipment.from.length > 1">
                                            <small v-if="shipment.from[0].datetime" class="data-table__state text-green">
                                                {{ $t('shipment.pickedUp', { date: dateFormat(shipment.from[0].datetime), time: dateTimeFormat(shipment.from[0].datetime) }) }}
                                            </small>
                                        </template>
                                        <template v-else>
                                            <small v-if="shipment.pickedUp" class="data-table__state text-green">
                                                {{ $t('shipment.pickedUp', { date: dateFormat(shipment.pickedUp), time: dateTimeFormat(shipment.pickedUp) }) }}
                                            </small>
                                        </template>
                                    </template>
                                </div>
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('shipment.to') }}</div>
                                <div class="data-table__count-container">
                                    <div class="data-table__count" v-if="shipment.to.length > 1">+{{ shipment.to.length - 1 }}</div>

                                    <Label :text="`${shipment.to[0].name}, ${shipment.to[0].location}`" />
                                </div>
                            </td>
                            <td class="data-table--no-break">
                                <div class="data-table__mobile-label">{{ $t('shipment.cargo') }}</div>
                                {{ $t('shipment.colis') }}: {{ shipment.numberOfColis }}<br>
                                <small>{{ $t('shipment.totalWeight', { weight: shipment.totalWeight }) }}</small>
                            </td>
                            <td class="data-table--no-break">
                                <div class="data-table__mobile-label">{{ $t('shipment.deliveryDate') }}</div>
                                {{ dateFormat(shipment.delivered) }}

                                <template v-if="getStateName(shipment) !== 'cancelled'">
                                    <template v-if="shipment.isTour && shipment.to.length > 1">
                                        <small v-if="shipment.to[0].datetime" class="data-table__state text-green">
                                            {{ $t('shipment.delivered', { date: dateFormat(shipment.to[0].datetime), time: dateTimeFormat(shipment.to[0].datetime) }) }}
                                        </small>
                                    </template>
                                    <template v-else>
                                        <small v-if="shipment.delivered" class="data-table__state text-green">
                                            {{ $t('shipment.delivered', { date: dateFormat(shipment.delivered), time: dateTimeFormat(shipment.delivered) }) }}
                                        </small>
                                    </template>
                                </template>
                            </td>
                            <td class="data-table--right tooltip_box">
                                <button class="data-table__button" @click="open(shipment)" style="margin-right: 7px"></button>
                                <div class="tooltip" v-if="showReOrderButton(shipment)">
                                    <router-link class="data-table__button data-table__button--repear" :to="{ name: 'Order', params: { archivedShipment: shipment.key } }">
                                    </router-link>
                                    <span class="tooltip__text">{{ $t('archive.orderAgainHint') }}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div ref="popup" role="modal" id="track" class="popup popup--wide popup--active popup--detail" v-if="detail">
                <div class="popup__container" ref="container">
                    <button class="popup__close" @click="close"></button>

                    <div class="grid-container">
                        <div class="grid-x grid-margin-x">
                            <div class="cell small-24 large-4">
                                <h4 class="popup__section-title">{{ $t('shipment.number') }}</h4>

                                <span class="popup__large-number">{{ detail.externalNumber }}</span>
                            </div>
                            <div class="cell small-23 large-5">
                                <h4 class="popup__section-title">{{ $t('shipment.information') }}</h4>

                                <p class="popup__info">
                                    <strong>{{ $t('shipment.reference') }}: {{ detail.reference }}</strong>
                                </p>
                                <p class="popup__info">
                                    {{ $t('shipment.colis') }}: {{ detail.numberOfColis }}<br>
                                    <small>{{ $t('shipment.totalWeight', { weight: detail.totalWeight }) }}</small>
                                </p>
                                <p v-if="detailShowPrices">
                                    <small v-if="detail.priceExclVat">{{ $t('shipment.priceExclVat', { price: detail.priceExclVat }) }}<br></small>
                                    <strong><small>{{ $t('shipment.priceInclVat', { price: detail.priceInclVat }) }}</small></strong>
                                </p>
                            </div>
                            <div class="cell small-23 large-5">
                                <h4 class="popup__section-title">{{ $t('shipment.sender') }}</h4>

                                <PopupAddressList :addresses="detail.from" :shipment="detail"></PopupAddressList>
                            </div>
                            <div class="cell small-23 large-5">
                                <h4 class="popup__section-title">{{ $t('shipment.receiver') }}</h4>
                                <PopupAddressList :addresses="detail.to" :shipment="detail"></PopupAddressList>
                            </div>
                            <div class="cell small-23 large-5">
                                <h4 class="popup__section-title">{{ $t('shipment.visierName') }}</h4>

                                {{ detail.visierName }}
                            </div>
                        </div>
                        <div class="grid-x grid-margin-x">
                            <div class="cell small-24 large-4">
                            </div>
                            <div class="cell small-23 large-5">
                                <p class="popup__info">
                                    <PodEmailForm :shipment="detail"/>
                                </p>
                            </div>
                            <div class="cell small-23 large-5 large button__block__down">
                                <router-link v-if="showReOrderButton(currentShipment)" class="button button--small button__block__down__align-down"
                                             :to="{ name: 'Order', params: { archivedShipment: currentShipment.key } }"
                                >
                                    {{ $t('archive.orderAgain') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card__footer" v-if="initialized && shipments.length > 0">
                <Pagination
                    :page="page"
                    :per-page="perPage"
                    :total="total"
                    :initialized="initialized"
                    :updating="updating"

                    @pageChanged="page = $event"
                    @perPageChanged="perPage = $event"
                ></Pagination>
            </div>

            <div v-if="initialized && shipments.length <= 0">
                {{ $t('archive.empty') }}
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import $ from 'jquery';
import axios from '@/config/axios';

import Pagination from '@/components/Pagination.vue';
import TableSortingField from '@/components/TableSortingField.vue';
import ShipmentFilter from '@/components/ShipmentFilter.vue';
import PopupAddressList from '@/components/PopupAddressList.vue';
import Label from '@/components/MultiLineLabel.vue';
import Export from '@/components/Export.vue';

import { dateFormat, dateTimeFormat } from '@/services/date-formatter';
import { getStateName, transformFull } from '@/services/shipment-transformer';
import PodEmailForm from '@/components/PodEmailForm.vue';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('archive');

export default {
    name: 'Archive',

    components: {
        PodEmailForm,
        Export,
        Label,
        PopupAddressList,
        ShipmentFilter,
        TableSortingField,
        Pagination,
    },

    data: () => ({
        perPage: 20,
        page: 0,

        filter: {},
        sorting: {
            field: 'default',
            direction: true,
        },

        exportShipments: [],

        detail: null,
        detailLoading: false,
        detailShowPrices: false,
        currentShipment: null,
    }),

    metaInfo() {
        return {
            title: this.$t('archive.title'),
        };
    },

    computed: {
        ...mapGetters([
            'total',
            'initialized',
            'updating',
        ]),

        ...mapState([
            'shipments',
        ]),
    },

    methods: {
        async fetch() {
            await this.fetchShipments({
                count: this.perPage,
                offset: this.page * this.perPage,
                filter: this.filter,
                sorting: {
                    field: this.sorting.field,
                    direction: this.sorting.direction ? 'desc' : 'asc',
                },
            });
        },

        open(currentShipment) {
            this.currentShipment = currentShipment;
            this.detailLoading = true;

            $('html').addClass('html--fixed');
            $('body').addClass('body--fixed');

            axios({
                url: `/api/v2/shipments/${this.currentShipment.key}`,
                method: 'GET',
            })
                .then((response) => {
                    const { data } = response;

                    const shipment = transformFull(data);

                    this.detailLoading = false;
                    this.detail = shipment;

                    const now = moment.default();
                    const datePriceBorder = moment.default(this.detail.delivered).add(24, 'hours');

                    this.detailShowPrices = now.isAfter(datePriceBorder);
                });
        },

        close() {
            this.currentShipment = null;
            this.detailLoading = false;
            this.detail = null;

            $('html').removeClass('html--fixed');
            $('body').removeClass('body--fixed');
        },

        dateFormat,
        dateTimeFormat,
        getStateName,

        ...mapActions([
            'fetchShipments',
        ]),

        showReOrderButton(shipment) {
            return !!(shipment.from.length === 1 && shipment.to.length === 1 && shipment.transportart_id !== 4);
        },
    },

    watch: {
        perPage() {
            this.exportShipments = [];
            this.page = 0;
            this.fetch();
        },

        filter: {
            deep: true,
            handler() {
                this.exportShipments = [];
                this.page = 0;
                this.fetch();
            },
        },

        sorting: {
            deep: true,
            handler() {
                this.exportShipments = [];
                this.page = 0;
                this.fetch();
            },
        },

        page() {
            this.exportShipments = [];
            this.fetch();
        },
    },

    mounted() {
        this.$nextTick(() => {
            $(document).on('keyup', (event) => {
                if (event.keyCode === 27) {
                    this.close();
                }
            });
        });
    },
};
</script>
