<template>
    <div class="filter" :class="{ 'filter--open': panelOpen, 'filter--is-filtered': isFiltered }" ref="filter">
        <button class="filter__button" @click="open">{{ $t('filter.button') }}</button>
        <button class="filter__reset" @click="reset" v-if="isFiltered">{{ $t('filter.reset') }}</button>

        <div class="filter__panel" @keydown="handleKeydown">
            <button class="filter__close" @click="close"></button>

            <div class="grid-container">
                <div class="filter__inner-container grid-x grid-margin-x">
                    <div class="cell small-24">
                        <div class="grid-container">
                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24">
                                    <h3 class="filter__title">{{ $t('filter.title') }}</h3>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.number" type="text">

                                        <span class="form-field__label">{{ $t('filter.number') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-12">
                                    <div class="form-field" v-if="showState">
                                        <div class="form-field" :class="{ 'form-field--has-value': filter.state }">
                                            <v-select
                                                v-model.lazy="filter.state"
                                                :value="filter.state"
                                                :options="states"
                                                :filterable="false"
                                                :clearable="true"
                                                :searchable="false"
                                                :select-on-tab="true"
                                            >
                                                <template #no-options>
                                                    {{ $t('filter.indicator') }}
                                                </template>
                                            </v-select>

                                            <span class="form-field__label">{{ $t('filter.state') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.sender" type="text">

                                        <span class="form-field__label">{{ $t('filter.sender') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.receiver" type="text">

                                        <span class="form-field__label">{{ $t('filter.receiver') }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-4">
                                    <div class="form-field">
                                        <input v-model="filter.senderZip" type="text">

                                        <span class="form-field__label">{{ $t('filter.senderZip') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-8">
                                    <div class="form-field">
                                        <input v-model="filter.senderLocation" type="text">

                                        <span class="form-field__label">{{ $t('filter.senderLocation') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-4">
                                    <div class="form-field">
                                        <input v-model="filter.receiverZip" type="text">

                                        <span class="form-field__label">{{ $t('filter.receiverZip') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-8">
                                    <div class="form-field">
                                        <input v-model="filter.receiverLocation" type="text">

                                        <span class="form-field__label">{{ $t('filter.receiverLocation') }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.reference" type="text">

                                        <span class="form-field__label">{{ $t('filter.reference') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-6">
                                    <div class="form-field">
                                        <datepicker
                                            v-model="filter.from"
                                            :language="languages[this.$i18n.locale]"
                                            :monday-first="true"
                                            format="dd.MM.yyyy"
                                            name="from"
                                        />

                                        <span class="form-field__suffix form-field__suffix--calendar" />
                                        <span class="form-field__label">{{ $t('filter.from') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-6">
                                    <div class="form-field">
                                        <datepicker
                                            v-model="filter.to"
                                            :language="languages[this.$i18n.locale]"
                                            :monday-first="true"
                                            format="dd.MM.yyyy"
                                            name="from"
                                        />

                                        <span class="form-field__suffix form-field__suffix--calendar" />
                                        <span class="form-field__label">{{ $t('filter.to') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field" :class="{ 'form-field--has-value': filter.transportTypeId }" v-if="showTransportType">
                                        <v-select
                                            v-model.lazy="filter.transportTypeId"
                                            :value="filter.transportTypeId"
                                            :options="transportTypes"
                                            :filterable="false"
                                            :clearable="true"
                                            :searchable="false"
                                            :select-on-tab="true"
                                        >
                                            <template #no-options>
                                                {{ $t('filter.indicator') }}
                                            </template>
                                        </v-select>

                                        <span class="form-field__label">{{ $t('filter.transportType') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="button" @click="apply">{{ $t('filter.apply') }}</button>
                        <button class="button button--white" @click="reset" v-if="isFiltered">{{ $t('filter.reset') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    de, en, fr, it,
} from 'vuejs-datepicker/dist/locale';
import $ from 'jquery';

export default {
    name: 'ShipmentFilter',

    props: {
        initialState: {
            type: String,
            required: false,
            default: '',
        },
        initialDate: {
            type: String,
            required: false,
            default: '',
        },
        showState: {
            type: Boolean,
            default: false,
        },
        showTransportType: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        emptyFilter: {
            number: '',
            sender: '',
            senderZip: '',
            senderLocation: '',
            receiver: '',
            receiverZip: '',
            receiverLocation: '',
            transportTypeId: 0,
            state: null,
            from: null,
            to: null,
        },

        filter: {
            number: '',
            sender: '',
            senderZip: '',
            senderLocation: '',
            receiver: '',
            receiverZip: '',
            receiverLocation: '',
            transportTypeId: 0,
            state: null,
            from: null,
            to: null,
        },

        panelOpen: false,
        languages: {
            de,
            en,
            fr,
            it,
        },
    }),

    mounted() {
        this.$nextTick(() => {
            if (this.initialState) {
                this.filter.state = this.states.find((state) => state.id === this.initialState);
                this.panelOpen = true;
            }

            if (this.initialDate) {
                const parsedDate = new Date(this.initialDate);
                if (!Number.isNaN(parsedDate)) {
                    const dateString = parsedDate.toISOString().split('T')[0];
                    this.filter.from = dateString;
                    this.filter.to = dateString;
                    this.panelOpen = true;
                }
            }

            this.apply();
        });
    },

    computed: {
        isFiltered() {
            return JSON.stringify(this.emptyFilter) !== JSON.stringify(this.filter);
        },

        transportTypes() {
            return [
                { id: 0, label: this.$t('filter.transportTypes.0') },
                { id: 1, label: this.$t('filter.transportTypes.1') },
                { id: 3, label: this.$t('filter.transportTypes.3') },
                { id: 4, label: this.$t('filter.transportTypes.4') },
            ];
        },

        states() {
            return [
                { id: 'readyForPickup', label: this.$t('global.states.readyForPickup') },
                { id: 'inTransit', label: this.$t('global.states.inDelivery') },
            ];
        },
    },

    watch: {
        filter: {
            handler() {
                $(document).trigger('value-changed');
            },
            deep: true,
        },
    },

    methods: {
        apply() {
            const data = {
                transportTypeId: this.filter.transportTypeId ? this.filter.transportTypeId.id : null,
                from: this.filter.from,
                to: this.filter.to,
                sender: this.filter.sender,
                senderZip: this.filter.senderZip,
                senderLocation: this.filter.senderLocation,
                receiver: this.filter.receiver,
                receiverZip: this.filter.receiverZip,
                receiverLocation: this.filter.receiverLocation,
                orderNumber: this.filter.number,
            };

            if (this.showState) {
                data.state = this.filter.state ? this.filter.state.id : null;
            }

            if (this.showReference) {
                data.reference = this.filter.reference;
            }

            // Explicitly mapped, as we don't want to have reactive fields
            this.$emit('filter', data);
        },

        open() {
            this.panelOpen = true;
        },

        close() {
            this.panelOpen = false;
        },

        reset() {
            this.filter = { ...this.emptyFilter };

            this.apply();
        },

        handleKeydown(event) {
            if (event.target.tagName === 'INPUT'
                && event.target.type === 'text'
                && event.key === 'Enter') {
                this.apply();
            }
        },
    },
};
</script>
