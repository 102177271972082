<template>
    <div class="order-confirm-form">

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div class="info-boxes" v-if="form">
                    <div class="grid-container">
                        <div class="grid-x grid-margin-x grid-margin-y">
                            <div class="cell small-24 xlarge-12">
                                <div class="info-box">
                                    <!-- Ihre Sendung -->
                                    <h3 class="info-box__title">{{ $t('orderConfirmForm.order.title') }}</h3>

                                    <ul class="info-box__list">
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.order.date') }}</div>
                                            <div class="info-box__list-value">{{ dateFormat(form.date) }}</div>
                                        </li>
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.order.time') }}</div>
                                            <div class="info-box__list-value">{{ timeFormat(form.time) }}</div>
                                        </li>
                                    </ul>

                                    <!-- Informationen zur Sendung -->
                                    <h3 class="info-box__title">{{ $t('orderConfirmForm.coli.title') }}</h3>

                                    <ul class="info-box__list">
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.coli.count') }}</div>
                                            <div class="info-box__list-value">{{ form.colis.count }}</div>
                                        </li>
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.coli.mass') }}</div>
                                            <div class="info-box__list-value">
                                                <div v-for="(coli, index) in form.colis.colis" :key="index">
                                                    {{ coli.height }}{{ $t('orderConfirmForm.coli.cm') }} x
                                                    {{ coli.length }}{{ $t('orderConfirmForm.coli.cm') }} x
                                                    {{ coli.width }}{{ $t('orderConfirmForm.coli.cm') }},
                                                    {{ coli.weight }}{{ $t('orderConfirmForm.coli.kg') }}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="cell small-24 xlarge-12">
                                <div class="info-box">
                                    <!-- Abholort -->
                                    <h3 class="info-box__title">{{ $t('orderConfirmForm.sender.title') }}</h3>

                                    <ul class="info-box__list">
                                        <!-- Company / Name -->
                                        <li class="info-box__list-item" v-if="form.sender.type === 'company'">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.company') }}</div>
                                            <div class="info-box__list-value">{{ form.sender.name }}</div>
                                        </li>
                                        <li class="info-box__list-item" v-else>
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.name') }}</div>
                                            <div class="info-box__list-value">{{ form.sender.name }}</div>
                                        </li>

                                        <!-- Contact -->
                                        <li class="info-box__list-item" v-if="form.sender.contact">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.contact') }}</div>
                                            <div class="info-box__list-value">{{ form.sender.contact }}</div>
                                        </li>

                                        <!-- Street -->
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.street') }}</div>
                                            <div class="info-box__list-value">{{ form.sender.street }}</div>
                                        </li>

                                        <!-- Location -->
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.location') }}</div>
                                            <div class="info-box__list-value">{{ form.sender.zip }} {{ form.sender.location }}</div>
                                        </li>

                                        <!-- Phone -->
                                        <li class="info-box__list-item" v-if="form.sender.phone">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.phone') }}</div>
                                            <div class="info-box__list-value">{{ form.sender.phone }}</div>
                                        </li>
                                    </ul>

                                    <!-- Zustellort -->
                                    <h3 class="info-box__title">{{ $t('orderConfirmForm.receiver.title') }}</h3>

                                    <ul class="info-box__list">
                                        <!-- Company / Name -->
                                        <li class="info-box__list-item" v-if="form.receiver.type === 'company'">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.company') }}</div>
                                            <div class="info-box__list-value">{{ form.receiver.name }}</div>
                                        </li>
                                        <li class="info-box__list-item" v-else>
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.name') }}</div>
                                            <div class="info-box__list-value">{{ form.receiver.name }}</div>
                                        </li>

                                        <!-- Contact -->
                                        <li class="info-box__list-item" v-if="form.receiver.contact">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.contact') }}</div>
                                            <div class="info-box__list-value">{{ form.receiver.contact }}</div>
                                        </li>

                                        <!-- Street -->
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.street') }}</div>
                                            <div class="info-box__list-value">{{ form.receiver.street }}</div>
                                        </li>

                                        <!-- Location -->
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.location') }}</div>
                                            <div class="info-box__list-value">{{ form.receiver.zip }} {{ form.receiver.location }}</div>
                                        </li>

                                        <!-- Phone -->
                                        <li class="info-box__list-item" v-if="form.receiver.phone">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.phone') }}</div>
                                            <div class="info-box__list-value">{{ form.receiver.phone }}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="cell small-24 xlarge-12">
                                <div class="info-box">
                                    <!-- Bemerkungen -->
                                    <h3 class="info-box__title">{{ $t('orderConfirmForm.notes.title') }}</h3>

                                    <p v-if="form.notes" class="info-box__text">{{ form.notes }}</p>
                                    <p v-else class="info-box__text">{{ $t('orderConfirmForm.notes.empty') }}</p>
                                </div>
                            </div>
                            <div class="cell small-24 xlarge-12">
                                <div class="info-box">
                                    <!-- Rechnung -->
                                    <h3 class="info-box__title">{{ $t('orderConfirmForm.invoice.title') }}</h3>

                                    <ul class="info-box__list">
                                        <!-- Company / Name -->
                                        <li class="info-box__list-item" v-if="form.invoiceAddress.type === 'company'">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.company') }}</div>
                                            <div class="info-box__list-value">{{ form.invoiceAddress.name }}</div>
                                        </li>
                                        <li class="info-box__list-item" v-else>
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.name') }}</div>
                                            <div class="info-box__list-value">{{ form.invoiceAddress.name }}</div>
                                        </li>

                                        <!-- Contact -->
                                        <li class="info-box__list-item" v-if="form.invoiceAddress.contact && form.invoiceType === 'single'">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.contact') }}</div>
                                            <div class="info-box__list-value">{{ form.invoiceAddress.contact }}</div>
                                        </li>

                                        <!-- Street -->
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.street') }}</div>
                                            <div class="info-box__list-value">{{ form.invoiceAddress.street }}</div>
                                        </li>

                                        <!-- Location -->
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.location') }}</div>
                                            <div class="info-box__list-value">{{ form.invoiceAddress.zip }} {{ form.invoiceAddress.location }}</div>
                                        </li>

                                        <!-- Spacer -->
                                        <li class="info-box__list-item">&nbsp;</li>

                                        <!-- Abrechnungsart -->
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.invoiceType.label') }}</div>
                                            <div class="info-box__list-value">{{ $t(`orderConfirmForm.address.invoiceType.${form.invoiceType}`) }}</div>
                                        </li>

                                        <!-- Email -->
                                        <li class="info-box__list-item" v-if="form.invoiceType === 'single'">
                                            <div class="info-box__list-key">{{ $t('orderConfirmForm.address.email') }}</div>
                                            <div class="info-box__list-value">{{ form.invoiceMail }}</div>
                                        </li>

                                        <!-- Spacer -->
                                        <li class="info-box__list-item">&nbsp;</li>

                                        <!-- Referenz -->
                                        <li class="info-box__list-item">
                                            <div class="info-box__list-key">{{ $t(`orderConfirmForm.address.reference.${form.invoiceType}`) }}</div>
                                            <div class="info-box__list-value">{{ form.invoiceReference }}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <OrderResult v-if="result" :result="result" />

                <div v-if="error" class="price-calculator__error">
                    <div class="service-information">
                        <ul class="service-information__list">
                            <li class="service-information__entry">
                                <div class="service-information__description" v-html="error"></div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-24">
                        <div class="terms-of-service">
                            <ul class="checkbox-group checkbox-group--no-margin">
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: false } }">
                                            <input id="confirmation[tosAccepted]" v-model="tosAccepted" type="checkbox" :class="v.classes">
                                            <label for="confirmation[tosAccepted]" v-html="$t('orderConfirmForm.tos.accept', { url: $t('links.tos') })"></label>
                                        </ValidationProvider>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="grid-x grid-margin-x grid-margin-y" v-if="pickupTimeDisclaimer">
                    <div class="cell small-24">
                        <PickupTimeDisclaimer :time="form.time"/>
                    </div>
                </div>

                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-12">
                        <router-link :to="{ name: 'Order', query: { load: 1 } }" class="button button--white">{{ $t('orderConfirmForm.back') }}</router-link>
                    </div>
                    <div class="cell small-12 align-right">
                        <button type="submit" :class="{ button: true, 'button--arrow': true, 'button--loading': loading }" :disabled="!result || loading || error">
                            {{ $t('orderConfirmForm.submit') }}
                        </button>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex';
import {
    apiFormat,
    apiTimeFormat,
    dateFormat,
    timeFormat,
} from '@/services/date-formatter';
import OrderResult from '@/components/OrderResult.vue';
import { validatePickupTime } from '@/services/pickup-time-validator';
import { reverseTransform } from '@/services/shipment-transformer';
import * as moment from 'moment';
import axios from '@/config/axios';
import { saveAddress } from '@/services/address-transformer';
import PickupTimeDisclaimer from '@/components/PickupTimeDisclaimer.vue';
import WeightCalculator from '@/services/weight-calculator';

const { mapGetters, mapActions } = createNamespacedHelpers('order');

export default {
    name: 'OrderConfirmForm',
    components: { PickupTimeDisclaimer, OrderResult },

    data: () => ({
        result: null,
        error: null,
        loading: false,
        tosAccepted: true,

        pickupTimeDisclaimer: false,
    }),

    computed: {
        ...mapGetters({
            form: 'storedForm',
        }),

        ...mapGlobalGetters('security', [
            'user',
        ]),
    },

    mounted() {
        this.loading = true;

        const weight = WeightCalculator.calculate(this.form.colis.colis);

        axios({
            url: '/api/v2/get-price',
            method: 'GET',
            params: {
                to_zip: this.form.receiver.zip,
                to_location: this.form.receiver.location,
                from_zip: this.form.sender.zip,
                from_location: this.form.sender.location,
                date: apiFormat(this.form.date),
                time: apiTimeFormat(this.form.time),
                weight,
                colis: this.form.colis.count,
            },
        })
            .then((response) => {
                if (Array.isArray(response.data) && response.data.length === 0) {
                    this.loading = false;

                    // How do we properly generate routes for translations?
                    this.error = this.$t('priceCalculator.error.noresult', { url: '/offer' });

                    return;
                }

                this.result = response.data;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
                this.error = this.$t('priceCalculator.error.noresult', { url: '/offer' });
            });
    },

    methods: {
        submit() {
            this.loading = true;

            const pickupTimeValid = validatePickupTime(this.form.date);

            if (pickupTimeValid === false) {
                const { form } = this;

                form.date = moment.default(new Date());
                form.time = moment.default(new Date()).format('HHmm');

                this.saveFormChanges(form);

                this.pickupTimeDisclaimer = true;
                this.loading = false;

                return;
            }

            const data = reverseTransform(this.form, this.user, this.$i18n.locale);

            // Set to Order instead of Offer
            data.is_offer = false;
            data.transportart_id = this.result.transportart_id;
            data.website_offertbetrag = this.result.price_inkl_mwst;

            axios({
                url: '/api/v2/shipments/create',
                method: 'POST',
                data,
            }).then(() => {
                const addresses = [this.form.sender, this.form.receiver, this.form.invoiceAddress];
                const promises = [];

                addresses.forEach((address) => {
                    if (!address.save) {
                        return;
                    }

                    const promise = saveAddress(address);

                    promises.push(promise);
                });

                Promise.all(promises).then(() => {
                    this.loading = false;

                    const { emailLetter } = this.form;

                    Promise.all([
                        this.resetForm(),
                    ]).then(() => {
                        this.$router.push({
                            name: 'OrderThankYou',
                            params: {
                                mail: emailLetter,
                            },
                        });
                    });
                });
            });
        },

        dateFormat,
        timeFormat,

        ...mapActions({
            saveFormChanges: 'save',
            resetForm: 'clear',
        }),
    },
};
</script>
